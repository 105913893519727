.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 40px 20px; /* Added horizontal padding */
    box-sizing: border-box;
    overflow-y: auto; /* Allow scrolling if content exceeds viewport */
}

.exit-form, .user-details {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.exit-form:last-child, .user-details:last-child {
    margin-bottom: 40px; /* Ensure space at the bottom after the last form */
}

.exit-form {
    margin-bottom: 20px;
}

.exit-form h2, .user-details h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.exit-form input, .exit-form button, .user-details select, .user-details button {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.exit-form input:focus, .user-details select:focus {
    border-color: #007bff;
    outline: none;
}

.exit-form button, .user-details button {
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.exit-form button:hover, .user-details button:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    text-align: center;
}