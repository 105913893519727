/* General styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
}

/* Login form styles */
.login-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px; /* Fixed width for the form */
  text-align: center;
  max-height: 80vh; /* Limit form height to prevent overflow */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.login-form h2 {
  margin-bottom: 20px;
}

.login-form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

/* Home page styles */
.home-container {
  text-align: center;
}

.home-buttons {
  margin-top: 20px;
}

.home-buttons button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.home-buttons button:hover {
  background-color: #0056b3;
}
